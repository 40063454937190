<template>
	<div class="masters">
		<SubHeader title="ODO Masters" subtitle="ODOマスターズ" />

		<div class="container">
			<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link active" href="javascript:void(0);">
						2024（2023年度）
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/masters/2023/"> 2023（2022年度）</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/masters/2020/"> 2020（2019年度） </a>
				</li>
			</ul>

			<div class="row justify-content-center">
				<div class="col-lg-12">
					<h2>ODOマスターズ2024（2023年度）</h2>
					<h5>開催：2024年7月15日（月祝）、会場：住吉大社（吉祥殿）</h5>
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-6 offset-3 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/masters/2024/odomasters_01.jpg"
							class="img-fluid"
							@click="openGallery(0)"
						/>
						<span class="name">高山 得溶</span>
					</p>
				</div>
				<div class="col-4 text-center">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/masters/2024/odomasters_02.jpg"
							class="img-fluid"
							@click="openGallery(1)"
						/>
						<span class="name">由肥 元希</span>
					</p>
				</div>
				<div class="col-4 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/masters/2024/odomasters_03.jpg"
							class="img-fluid"
							@click="openGallery(2)"
						/>
						<span class="name">磯和 勇希</span>
					</p>
				</div>
				<div class="col-4 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/masters/2024/odomasters_04.jpg"
							class="img-fluid"
							@click="openGallery(3)"
						/>
						<span class="name">浅井 佑介</span>
					</p>
				</div>
			</div>

			<h4>集合写真＆決勝トーナメント表</h4>
			<div class="row mb-4">
				<div class="col-5 text-center offset-1">
					<p>
						<span class="rank">集合写真</span>
						<img
							src="/images/masters/2024/odomasters_05.jpg"
							class="img-fluid"
							@click="openGallery(4)"
						/>
					</p>
				</div>
				<div class="col-5 text-center">
					<p>
						<span class="rank">決勝トーナメント表</span>
						<img
							src="/images/masters/2024/odomasters_12.jpg"
							class="img-fluid"
							@click="openGallery(5)"
						/>
					</p>
				</div>
			</div>

			<div class="row mb-4">
				<div class="col-4">
					<p>
						<img
							src="/images/masters/2024/odomasters_06.jpg"
							class="img-fluid"
							@click="openGallery(6)"
						/>
					</p>
				</div>
				<div class="col-4">
					<p>
						<img
							src="/images/masters/2024/odomasters_07.jpg"
							class="img-fluid"
							@click="openGallery(7)"
						/>
					</p>
				</div>
				<div class="col-4">
					<p>
						<img
							src="/images/masters/2024/odomasters_08.jpg"
							class="img-fluid"
							@click="openGallery(8)"
						/>
					</p>
				</div>
				<div class="col-4">
					<p>
						<img
							src="/images/masters/2024/odomasters_09.jpg"
							class="img-fluid"
							@click="openGallery(9)"
						/>
					</p>
				</div>
				<div class="col-4">
					<p>
						<img
							src="/images/masters/2024/odomasters_10.jpg"
							class="img-fluid"
							@click="openGallery(10)"
						/>
					</p>
				</div>
				<div class="col-4">
					<p>
						<img
							src="/images/masters/2024/odomasters_11.jpg"
							class="img-fluid"
							@click="openGallery(11)"
						/>
					</p>
				</div>
			</div>

			<h4>エントリー</h4>
			<div class="row">
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/01_sotoosa.jpg" class="img-fluid" />
						<span class="name">外筬 久人</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/02_takayama.jpg" class="img-fluid" />
						<span class="name">高山 得溶</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/03_isowa.jpg" class="img-fluid" />
						<span class="name">磯和 勇希</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/04_kurokawa.jpg" class="img-fluid" />
						<span class="name">黒川 智成</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/05_odachi.jpg" class="img-fluid" />
						<span class="name">大達 浩司</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/06_nishioka.jpg" class="img-fluid" />
						<span class="name">西岡 健太郎</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/07_komura.jpg" class="img-fluid" />
						<span class="name">小村 亮</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/08_take.jpg" class="img-fluid" />
						<span class="name">嵩 竜宏</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/09_awajiya.jpg" class="img-fluid" />
						<span class="name">淡路屋 湧也</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/10_kawamoto.jpg" class="img-fluid" />
						<span class="name">川本 恵宏</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/11_hayashi.jpg" class="img-fluid" />
						<span class="name">林 和義</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/12_den.jpg" class="img-fluid" />
						<span class="name">田 達輝</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2024/13_shirohata.jpg"
							class="img-fluid"
						/>
						<span class="name">白畑 諒太</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/14_takeuchi.jpg" class="img-fluid" />
						<span class="name">竹内 英人</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/15_kawadu.jpg" class="img-fluid" />
						<span class="name">河津 俊宏</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/16_asai.jpg" class="img-fluid" />
						<span class="name">浅井 佑介</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/17_imoto.jpg" class="img-fluid" />
						<span class="name">井本 隆之</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/18_yanase.jpg" class="img-fluid" />
						<span class="name">柳瀬 将一</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/19_hirose.jpg" class="img-fluid" />
						<span class="name">廣瀬 拓郎</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/20_usuzawa.jpg" class="img-fluid" />
						<span class="name">臼沢 晋</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/no_image.jpg" class="img-fluid" />
						<span class="name">芳村 幹陽</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/22_morioka.jpg" class="img-fluid" />
						<span class="name">森岡 康之</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/23_kiyoi.jpg" class="img-fluid" />
						<span class="name">清井 寛司</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/24_yabuta.jpg" class="img-fluid" />
						<span class="name">薮田 和哉</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/25_tanida.jpg" class="img-fluid" />
						<span class="name">谷田 尚嗣</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/26_sunakawa.jpg" class="img-fluid" />
						<span class="name">砂川 祥輝</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/27_yuuhi.jpg" class="img-fluid" />
						<span class="name">由肥 元希</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/28_aoki.jpg" class="img-fluid" />
						<span class="name">青木 まゆ</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/29_tatara.jpg" class="img-fluid" />
						<span class="name">多田良 宏栄</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/30_iwatani.jpg" class="img-fluid" />
						<span class="name">岩谷 由人</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/31_minami.jpg" class="img-fluid" />
						<span class="name">南 真二</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img src="/images/masters/2024/32_kitamura.jpg" class="img-fluid" />
						<span class="name">北村 智加</span>
					</p>
				</div>
			</div>

			<!--<h4 class="mt-5">エントリー</h4>
			<div class="row mb-5">
				<div class="col-lg-6 col-12">
					<table class="table table-striped">
						<thead class="thead-dark">
							<tr>
								<th scope="col" class="text-nowrap">Player</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="(player, index) in list">
								<tr
									v-if="
										player != null &&
										player.number != '' &&
										player.attendance != ''
									"
									:key="'rank' + index"
								>
									<td class="text-nowrap">{{ player.player_name }}</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>-->

			<LightBox
				ref="lightbox"
				:media="media"
				:show-light-box="false"
				:show-caption="true"
			></LightBox>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
//import VueLazyLoad from "vue-lazyload";
import Loading from "vue-loading-overlay";
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

export default {
	name: "masters",
	components: {
		SubHeader,
		LightBox,
	},
	data() {
		return {
			isLoading: true,
			list: [],
			media: [
				{
					thumb: "/images/masters/2024/odomasters_01.jpg",
					src: "/images/masters/2024/odomasters_01.jpg",
					caption: "高山 得溶",
				},
				{
					thumb: "/images/masters/2024/odomasters_02.jpg",
					src: "/images/masters/2024/odomasters_02.jpg",
					caption: "由肥 元希",
				},
				{
					thumb: "/images/masters/2024/odomasters_03.jpg",
					src: "/images/masters/2024/odomasters_03.jpg",
					caption: "磯和 勇希",
				},
				{
					thumb: "/images/masters/2024/odomasters_04.jpg",
					src: "/images/masters/2024/odomasters_04.jpg",
					caption: "浅井 佑介",
				},
				{
					thumb: "/images/masters/2024/odomasters_05.jpg",
					src: "/images/masters/2024/odomasters_05.jpg",
					caption: "集合写真",
				},
				{
					thumb: "/images/masters/2024/odomasters_12.jpg",
					src: "/images/masters/2024/odomasters_12.jpg",
					caption: "決勝トーナメント表",
				},
				{
					thumb: "/images/masters/2024/odomasters_06.jpg",
					src: "/images/masters/2024/odomasters_06.jpg",
					caption: "",
				},
				{
					thumb: "/images/masters/2024/odomasters_07.jpg",
					src: "/images/masters/2024/odomasters_07.jpg",
					caption: "",
				},
				{
					thumb: "/images/masters/2024/odomasters_08.jpg",
					src: "/images/masters/2024/odomasters_08.jpg",
					caption: "",
				},
				{
					thumb: "/images/masters/2024/odomasters_09.jpg",
					src: "/images/masters/2024/odomasters_09.jpg",
					caption: "",
				},
				{
					thumb: "/images/masters/2024/odomasters_10.jpg",
					src: "/images/masters/2024/odomasters_10.jpg",
					caption: "",
				},
				{
					thumb: "/images/masters/2024/odomasters_11.jpg",
					src: "/images/masters/2024/odomasters_11.jpg",
					caption: "",
				},
			],
		};
	},
	mounted: function () {
		//this.getData();
	},
	methods: {
		getData: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {};
			this.axios
				.get(
					"https://script.google.com/macros/s/AKfycbw2ph-kgRHspt8DVaDk5kuTH-OWDSpIl2mWsr9ZFbGCs_ZJONj25TC7JQ_FZUVBqlQ/exec",
					{
						params: params,
					}
				)
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.list = res.data.list;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
		openGallery(index) {
			this.$refs.lightbox.showImage(index);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
h4 {
	border-bottom: solid 3px #333;
	padding-bottom: 5px;
	margin-bottom: 2rem;
	position: relative;
	&:before {
		content: "";
		width: 3rem;
		height: 3px;
		background-color: $primary-color;
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
	}
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.rank,
.rank_winner,
.rank_2nd,
.rank_3rd,
.name {
	color: #333;
}
.rank_winner {
	font-size: 2rem;
	i {
		color: #dbb400;
	}
}
.rank_2nd {
	font-size: 1.4rem;
	i {
		color: #9fa0a0;
	}
}
.rank_3rd {
	font-size: 1.4rem;
	i {
		color: #c47022;
	}
}
i {
	margin-right: 0.6rem;
}
</style>
